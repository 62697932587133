import { useAuth } from '@upper/auth'
import { RoleKey } from '@upper/graphql/auth'
import {
  CurrentTalentStatusDocument,
  Talent,
  TalentStatus,
} from '@upper/graphql/freelancer'
import { Button, Spinner } from '@upper/ui'
import { pick } from '@upper/utils'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useQuery } from 'urql'

type TalentStatusContextInterface = {
  talentId?: string | null
  talentStatus?: TalentStatus | null
  isTalentActive: boolean
  isTalentRejected: boolean
} & Pick<
  Talent,
  | 'availability'
  | 'nextAvailability'
  | 'hourlyRate'
  | 'minimumHourlyRate'
  | 'interestedInEmployment'
  | 'interestedInFreelance'
  | 'employmentYearlySalary'
  | 'rateNotes'
  | 'hasFreelancerAppAccess'
  | 'profiles'
  | 'verificationStatus'
  | 'canAttemptVerification'
>

const TalentStatusContext = React.createContext(
  {} as TalentStatusContextInterface
)

function WithTalentStatus({ children }: { children: React.ReactNode }) {
  const router = useRouter()
  const { user, userHasRole, logout } = useAuth()
  const [{ data, fetching, error }] = useQuery({
    query: CurrentTalentStatusDocument,
    pause: !user || userHasRole(RoleKey.StudioTalent),
  })

  const value = React.useMemo(
    () => ({
      talentId: data?.me?.talentData?.id,
      talentStatus: data?.me?.talentData?.status,
      isTalentActive:
        data?.me?.talentData?.status === TalentStatus.Active &&
        data?.me?.talentData?.hasFreelancerAppAccess === true,
      isTalentRejected: data?.me?.talentData?.status === TalentStatus.Rejected,
      ...pick(
        data?.me?.talentData ?? {},
        'availability',
        'nextAvailability',
        'hourlyRate',
        'minimumHourlyRate',
        'interestedInEmployment',
        'interestedInFreelance',
        'employmentYearlySalary',
        'rateNotes',
        'hasFreelancerAppAccess',
        'profiles',
        'verificationStatus',
        'canAttemptVerification'
      ),
    }),
    [data]
  )

  if (typeof window === 'undefined') return null

  if (user) {
    if (
      userHasRole(RoleKey.StudioTalent) &&
      !router.pathname.includes('timesheet')
    ) {
      router.replace('/timesheet')
    }

    if (!(userHasRole(RoleKey.Talent) || userHasRole(RoleKey.StudioTalent))) {
      return (
        <div className="p-8">
          <p>
            You don&apos;t have enough permissions to view the freelancer app
          </p>
          <div className="mt-10">
            <Button onClick={logout}>Logout</Button>
          </div>
        </div>
      )
    }

    if (fetching) {
      return (
        <div className="flex h-screen items-center justify-center">
          <Spinner />
        </div>
      )
    }

    if (error) {
      return <div>Error: {error.message}</div>
    }

    if (
      data?.me?.talentData?.status === TalentStatus.Registered &&
      !router.asPath.startsWith('/complete-profile')
    ) {
      window.location.replace('/complete-profile')
      return null
    }

    if (
      data?.me?.talentData?.status !== TalentStatus.Registered &&
      router.asPath.startsWith('/complete-profile')
    ) {
      window.location.replace('/')
      return null
    }
  }

  return (
    <TalentStatusContext.Provider value={value}>
      {children}
    </TalentStatusContext.Provider>
  )
}

export function useTalentStatus() {
  const context = React.useContext(TalentStatusContext)
  if (context === undefined) {
    throw new Error(
      `useTalentStatus must be used within a TalentStatusProvider`
    )
  }
  return context
}

export default WithTalentStatus
