import { TooltipProvider } from '@radix-ui/react-tooltip'
import { AuthProvider, createUrqlClient, WithAuthRedirect } from '@upper/auth'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import * as React from 'react'
import 'react-day-picker/dist/style.css'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'urql'
import WithTalentStatus from '../components/with-talent-status'
import './styles.css'
import '@upper/sapphire/ui/style'
import { cacheExchange } from '@urql/exchange-graphcache'
import {
  BaseTimesheetDayFragmentDoc,
  BaseTimesheetFragmentDoc,
  CreateAbsenceIntervalMutation,
  CreateAbsenceMutation,
  CreateExpenseMutation,
  CreateTimeEntryMutation,
  CreateTimesheetDayMutation,
  DeleteAbsenceMutation,
  DeleteExpenseMutation,
  DeleteTimeEntryMutation,
  MutationCreateAbsenceArgs,
  MutationCreateExpenseArgs,
  MutationCreateTimeEntryArgs,
  MutationCreateTimesheetDayArgs,
  MutationDeleteAbsenceArgs,
  MutationDeleteExpenseArgs,
  MutationDeleteTimeEntryArgs,
  MutationSubmitTimesheetArgs,
  MutationUpdateExpenseArgs,
  MutationUpdateTimeEntryArgs,
  TimesheetSubmitMutation,
  UpdateExpenseMutation,
  UpdateTimeEntryMutation,
} from '@upper/graphql/freelancer'

type NextPageWithLayoutAndAuth = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
  authenticate?: boolean
  redirectAuthenticatedTo?: string
}

type AppPropsWithLayoutAndAuth = AppProps & {
  Component: NextPageWithLayoutAndAuth
}

const APP_NAME = 'freelancer'

const urqlClient = createUrqlClient(
  APP_NAME,
  cacheExchange({
    keys: {
      TalentTimesheetTotals: (data) => {
        return null
      },
    },
    updates: {
      Mutation: {
        createAbsenceInterval: (
          result: CreateAbsenceIntervalMutation,
          args: MutationCreateAbsenceArgs,
          cache,
          info
        ) => {
          result.createAbsenceInterval.forEach((td) => {
            const timesheetFragment = cache.readFragment(
              BaseTimesheetFragmentDoc,
              {
                __typename: 'Timesheet',
                id: td.engagementId ?? '',
              }
            )
            const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
              __typename: 'TimesheetDay',
              id: td.id ?? '',
            })

            if (fragment) {
              td.absences?.forEach((tda) => {
                fragment.absences?.push(tda)
              })
              cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
            } else {
              timesheetFragment?.days?.push(td)
              cache.writeFragment(BaseTimesheetFragmentDoc, timesheetFragment)
            }
          })
        },
        updateTimeEntry: (
          result: UpdateTimeEntryMutation,
          args: MutationUpdateTimeEntryArgs,
          cache,
          info
        ) => {
          const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
            __typename: 'TimesheetDay',
            id: result.updateTimeEntry?.timesheetDayId,
          })
          if (fragment) {
            fragment.totalHours = fragment.timeEntries?.reduce((acc, v) => {
              return (acc += v.hours ?? 0)
            }, 0)
            cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
          }
        },
        updateExpense: (
          result: UpdateExpenseMutation,
          args: MutationUpdateExpenseArgs,
          cache,
          info
        ) => {
          const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
            __typename: 'TimesheetDay',
            id: result.updateExpense?.timesheetDayId,
          })
          if (fragment) {
            fragment.totalExpenses = fragment.expenses?.reduce((acc, v) => {
              return (acc += v.amount ?? 0)
            }, 0)
            cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
          }
        },
        submitTimesheet: (
          result: TimesheetSubmitMutation,
          args: MutationSubmitTimesheetArgs,
          cache,
          info
        ) => {
          const fragment = cache.readFragment(BaseTimesheetFragmentDoc, {
            __typename: 'Timesheet',
            id: args.engagementId,
          })
          if (fragment) {
            result.submitTimesheet.days?.map((td) => {
              if (!fragment.days?.find((fd) => fd.id === td.id))
                fragment.days?.push(td)
            })
            cache.writeFragment(BaseTimesheetFragmentDoc, fragment)
          }
        },
        createTimesheetDay: (
          result: CreateTimesheetDayMutation,
          args: MutationCreateTimesheetDayArgs,
          cache,
          info
        ) => {
          const fragment = cache.readFragment(BaseTimesheetFragmentDoc, {
            __typename: 'Timesheet',
            id: args.data.engagementId,
          })
          if (fragment) {
            fragment.days?.push(result.createTimesheetDay)
            cache.writeFragment(BaseTimesheetFragmentDoc, fragment)
          }
        },
        createTimeEntry: (
          result: CreateTimeEntryMutation,
          args: MutationCreateTimeEntryArgs,
          cache,
          info
        ) => {
          const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
            __typename: 'TimesheetDay',
            id: args.data.timesheetDayId,
          })
          if (fragment) {
            fragment?.timeEntries?.push(result.createTimeEntry)
            cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
          }
        },
        deleteTimeEntry: (
          result: DeleteTimeEntryMutation,
          args: MutationDeleteTimeEntryArgs,
          cache,
          info
        ) => {
          if (result.deleteTimeEntry)
            cache.invalidate({
              __typename: 'TimeEntry',
              id: args.id,
            })
        },
        createAbsence: (
          result: CreateAbsenceMutation,
          args: MutationCreateAbsenceArgs,
          cache,
          info
        ) => {
          const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
            __typename: 'TimesheetDay',
            id: args.data.timesheetDayId,
          })
          if (fragment) {
            fragment?.absences?.push(result.createAbsence)
            cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
          }
        },
        deleteAbsence: (
          result: DeleteAbsenceMutation,
          args: MutationDeleteAbsenceArgs,
          cache,
          info
        ) => {
          if (result.deleteAbsence)
            cache.invalidate({
              __typename: 'Absence',
              id: args.id,
            })
        },
        createExpense: (
          result: CreateExpenseMutation,
          args: MutationCreateExpenseArgs,
          cache,
          info
        ) => {
          const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
            __typename: 'TimesheetDay',
            id: args.data.timesheetDayId,
          })
          if (fragment) {
            fragment?.expenses?.push(result.createExpense)
            cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
          }
        },
        deleteExpense: (
          result: DeleteExpenseMutation,
          args: MutationDeleteExpenseArgs,
          cache,
          info
        ) => {
          if (result.deleteExpense)
            cache.invalidate({
              __typename: 'Expense',
              id: args.id,
            })
        },
      },
    },
  })
)

function CustomApp({ Component, pageProps }: AppPropsWithLayoutAndAuth) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_FREELANCER}');
          `,
        }}
      />
      <Provider value={urqlClient}>
        <AuthProvider appName={APP_NAME}>
          <WithAuthRedirect
            authenticate={Component.authenticate}
            redirectAuthenticatedTo={Component.redirectAuthenticatedTo}
          >
            <WithTalentStatus>
              <TooltipProvider>
                {getLayout(<Component {...pageProps} />)}
              </TooltipProvider>
            </WithTalentStatus>
          </WithAuthRedirect>
        </AuthProvider>
        <Toaster />
      </Provider>
    </>
  )
}

export default CustomApp
